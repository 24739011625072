import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { theme } from "@/core/theme/themes";
import { ThemeProvider } from "@mui/material/styles";
import "@fontsource/eb-garamond";
import { RouterMap } from "@/routes";
import "./core/plugin/i18n";
import "../src/styles/global.css";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <RouterMap />
    </ThemeProvider>
  </BrowserRouter>,
);
