import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export type SeoProps = {
  title?: string;
  description?: string;
};

export const Seo: React.FunctionComponent<SeoProps> = ({
  title,
  description,
}) => {
  const metaTitle = title || "ANCHOR BRIDGE";
  const metaDescription =
    description || "ANCHOR BRIDGE offers virtual currency bridge services.";

  const faviconLinks = [
    {
      rel: "icon",
      href: "/images/favicon.png",
    },
  ];

  return (
    <HelmetProvider>
      <Helmet
        htmlAttributes={{
          lang: `zh-TW`,
        }}
        title={metaTitle}
        link={faviconLinks}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: `@maio`,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]}
      />
    </HelmetProvider>
  );
};
