import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: "#172443",
    },
    btc: {
      light: "#FFF0E2",
      main: "#ECA55F",
      dark: "#E5811F",
      contrastText: "#FFFCFA",
    },
    div: {
      light: "#E8F3FF",
      main: "#497EB8",
      dark: "#004A9B",
      contrastText: "#F5FAFF",
    },
    success: {
      main: "#008C25",
    },
    error: {
      main: "#A9001E",
    },
    background: {
      default: "#FFFCFA",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#172443",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
    },
  },
  typography: {
    fontFamily: ["EB Garamond", "sans-serif"].join(","),
    fontWeightRegular: 500,
    fontWeightBold: 700,
  },
});
