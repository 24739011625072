import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { enTranslation } from "@/core/constant/enTranslation";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: "en",
    resources: {
      en: {
        translation: enTranslation,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .catch();

export default i18next;
