import React, { ReactElement, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import NotFound from "@/pages/notFound";

const LayoutPage = React.lazy(
  () => import("@/components/common/layout/Layout"),
);

const IndexPage = React.lazy(() => import("@/pages/index"));
const BridgePage = React.lazy(() => import("@/pages/bridge"));
const SinglePage = React.lazy(() => import("@/pages/single"));
const TokensPage = React.lazy(() => import("@/pages/tokens"));

export const RouterMap = (): ReactElement | null =>
  useRoutes([
    {
      element: (
        <Suspense>
          <LayoutPage />
        </Suspense>
      ),
      children: [
        {
          path: "/",
          element: (
            <Suspense>
              <IndexPage />
            </Suspense>
          ),
        },
        {
          path: "/bridge",
          element: (
            <Suspense>
              <BridgePage />
            </Suspense>
          ),
        },
        {
          path: "/single/:symbol",
          element: (
            <Suspense>
              <SinglePage />
            </Suspense>
          ),
        },
        {
          path: "/tokens",
          element: (
            <Suspense>
              <TokensPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/404",
      element: (
        <Suspense>
          <NotFound />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
