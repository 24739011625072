import { Translation } from "@/types/Translation";

export const enTranslation: Translation = {
  str_home: "Home",
  str_diver_coin_rating: "Diver Coin Rating",
  str_bitcoin_rating: "Bitcoin Rating",
  str_coin_list: "Coin List",
  str_bridge: "Bridge",
  str_how_to_use: "How to use?",
  str_mobile_app: "Mobile App",
  str_pc: "PC",
  str_please_fill_out_this_field: "Please fill out this field",
  str_invalid_address: "Invalid address",
  str_launch_app: "Launch App",
  str_please_enter_receiving_address: "Please enter your receiving address",
  str_search_code_issued:
    "Search Code is a Reference Code issued after Bridge.",
  str_please_enter_transaction_code: "Transaction Code",
  str_search: "Search",
  str_search_result_for: "Search result for",
  str_from: "From",
  str_to: "To",
  str_copy: "Copy",
  str_transaction_fee: "Transaction Fee",
  str_transaction_details: "Transaction Details",
  str_blockchain: "Blockchain",
  str_status: "Status",
  str_timestamp: "TimeStamp",
  str_complete: "Complete",
  str_revert: "Revert",
  str_in_progress: "In Progress",
  str_error: "Error",
  str_remit: "Remit",
  str_confirm: "Confirm",
  str_download_diver_wallet: "Download Diver Wallet",
  str_dear_customer: "Dear Customer",
  str_website_guideline:
    "There are precautions for bridging from Bitcoin (BTC) to DIVER Coin (DIV).\n" +
    "When bridging from Bitcoin (BTC) to DIVER Coin (DIV), you need to approve a certain number of Bitcoin (BTC) transactions for security reasons.\n" +
    "The bridge from DIVER coins (DIV) to Bitcoin (BTC) requires you to approve a predetermined number of DIVER coins (DIV) transactions for security reasons.\n" +
    "This takes about 2 seconds.\n" +
    "However, there are also bitcoin (BTC) transactions after that, so it depends on bitcoin transactions.\n" +
    "Also, for safety reasons, the maximum trading volume per day is about 5 BTC.\n" +
    "The minimum purchase amount of DIV in Anchor Bridge is 1,000,000 DIV.\n" +
    "Anchor Bridge does not support transaction if insufficient amounts are entered.",
  str_top_coins_on_Anchor_Bridge: "Top Coins on Anchor Bridge",
  str_coin_name: "Coin Name",
  str_price: "Price",
  str_change_percentage_minute: "Change % 1 Minute",
  str_back_to_Coin_List: "Back to Coin List",
  str_minute_chart: "Minute Chart",
  str_about: "About",
  str_about_guideline:
    "What is DIVER?\n" +
    "DIVER is the next generation of blockchain. It features high speed, no fees, and Smart Contract implementation. Token, NFT, and Smart Contract are available. The power consumption is extremely low, making Mining environmentally friendly.\n" +
    "DIVER's goal is to become the Global Platform for Decentralized Application. We want to enable users around the world to create and run software that is resistant to censorship, server downtime, and counterfeiting.\n" +
    "What makes DIVER unique?\n" +
    "DIVER wants to remove all the hard-to-use aspects of other blockchains. BTC is slow to transfer money, Ethereum has high fees for Token transfers. We want to change this part.\n" +
    "You and I deal with cryptocurrencies on a daily basis. We are trying to improve the parts that we find difficult to use by sharing our opinions. Ideally, we would like to offer a Product that adopts the good parts of other cryptocurrencies and removes the bad parts.",
  str_bridge_fee: "Bridge Fee",
  str_bridge_guideline:
    "When converting BTC to DIV, we accept a minimum of <0>0.0001</0> BTC. Anchor Bridge will not convert to DIV if you send a quantity of less than <0>0.0001</0> BTC. The minimum purchase amount of DIV in Anchor Bridge is <0>1,000,000</0> DIV. Anchor Bridge does not support transaction if insufficient amounts are entered.",
  str_enter_transaction_guideline:
    "Please enter the exact transaction code sent to the anchor bridge address above. If you make a mistake, you cannot bridge.",
  str_transaction_Code_placeholder:
    "Please enter only {{symbolName}} transaction code",
  str_confirm_bridge: "Confirm Bridge",
  str_your_address: "Your address",
  str_anchor_bridge_address: "Anchor Bridge address",
  str_receive: "Receive",
  str_receive_guideline:
    "This address is for receiving {{symbolName}} ({{symbol}}). If you send other coins, you may lose your coins.",
  str_expected_output: "Expected Output",
  str_search_id: "Search ID",
  str_please_copy_search: "Please Copy Search ID!!",
  str_minimum_amount_div:
    "The minimum purchase amount of DIV in Anchor Bridge is 1,000,000 DIV",
  str_minimum_amount_btc:
    "The minimum purchase amount of BTC in Anchor Bridge is 0.0001 BTC",
  str_successfully_submitted: "Successfully submitted",
  str_failed_to_fetch: "Failed to fetch",
};
